import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"
import Product1 from "../../images/product/commerce2x-merchant.png";

import { Link } from "gatsby"
import { ExpandableTile,TileAboveTheFoldContent } from 'carbon-components-react';


const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Impact your customers</Subtitle>
      <GetStartedTitle>Online store and marketplace commerce cloud for powerful and growing retailers</GetStartedTitle>
      <SectionSubTitle>With responsive themes and a comprehensive site manager, you can create a unique store — without code — in minutes. Order management, modern sales tools and powerful built-in features designed to take you from one stage of growth to the next.</SectionSubTitle>
      <img src={Product1} style={{width:'100%'}}></img>
      <FeaturesGrid>
        <FeatureItem>
         
          <FeatureText>
          <ExpandableTile style={{backgroundColor:'', marginTop:'20px'}}>
                    <TileAboveTheFoldContent>
                      <div style={{ height: '220px', color:'#0F39BF' }}><h3>B2B Commerce Cloud</h3>
                      <p style={{fontSize:'18px', color:"#000"}}>Enable a better B2B ecommerce experience by utilizing automated customer and pricing segmentation, punch out, purchase orders.</p>
                      </div>
                    </TileAboveTheFoldContent>
                  
          </ExpandableTile>

          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          
          <FeatureText>
          <ExpandableTile style={{ marginTop:'20px'}}>
                      <TileAboveTheFoldContent>
                        <div style={{ height: '220px', color:'#0F39BF' }}><h3>Exponential Revenue Growth</h3>
                        
                        <p style={{fontSize:'18px', color:"#000"}}>Grow revenue faster with Commerce2x modern commerce cloud built around your customer.</p>
                       
                        
                        </div>
                      </TileAboveTheFoldContent>
                      {/* <TileBelowTheFoldContent>
                        <div style={{ height: '50px' }}>Below the fold content here</div>
                      </TileBelowTheFoldContent> */}
                </ExpandableTile>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
         
          <FeatureText>
          <ExpandableTile style={{marginTop:'20px'}}>
                      <TileAboveTheFoldContent>
                        <div style={{ height: '220px', color:'#0F39BF' }}><h3>Order Management System</h3>
                        <p style={{fontSize:'18px', color:"#000"}}>Meet the order management system that’s built for your customers and your busiest days.</p>
                        </div>
                      </TileAboveTheFoldContent>
                     
                </ExpandableTile>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
         
          <FeatureText>
          <ExpandableTile style={{marginTop:'20px'}}>
                      <TileAboveTheFoldContent>
                        <div style={{ height: '220px', color:'#0F39BF' }}><h3>Sell on Multiple Channels</h3>
                        <p style={{fontSize:'18px', color:"#000"}}>Connect multiple channels like eCommerce platforms and marketplaces and sell your products directly on the platform by easily exporting to the respective channels.</p>
                        </div>
                      </TileAboveTheFoldContent>
                     
                </ExpandableTile>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          
          <FeatureText> <ExpandableTile style={{marginTop:'20px'}}>
                  <TileAboveTheFoldContent>
                    <div style={{ height: '240px', color:'#0F39BF' }}><h3>Multi-Store Inventory</h3>
                    <p style={{fontSize:'18px', color:"#000"}}>Connect your store with multiple inventory source to manage multi-channel inventories from a single website and easily track your product stock in a particular inventory.</p>
                    </div>
                  </TileAboveTheFoldContent>
                  
            </ExpandableTile></FeatureText>
        </FeatureItem>
        <FeatureItem>
          
          <FeatureText>
          <ExpandableTile style={{marginTop:'20px'}}>
                      <TileAboveTheFoldContent>
                        <div style={{ height: '240px', color:'#0F39BF' }}><h3>Headless Commerce Architecture</h3>
                        <p style={{fontSize:'18px', color:"#000"}}>With Commerce2x ecommerce solution, you can plug in via APIs without having to replace entire systems. An api first enabled single platform play.</p>
                        </div>
                      </TileAboveTheFoldContent>
                      
                </ExpandableTile>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          
          <FeatureText>
          <ExpandableTile style={{marginTop:'20px'}}>
                  <TileAboveTheFoldContent>
                    <div style={{ height: '240px', color:'#0F39BF' }}><h3>Progressive Web App (PWA)</h3>
                    <p style={{fontSize:'18px', color:"#000"}}>PWA uses web compatibilities and provides a native app experience to the user. With Commerce2x PWA, Customer can view your website in offline mode to increase the customer engagement rate.</p>
                    </div>
                  </TileAboveTheFoldContent>
                  
            </ExpandableTile>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          
          <FeatureText>
          <Link to="/platform" activeStyle={{ color: "white" }} style={{textDecoration:'none', color:'#fff'}}><TryItButton style={{marginTop:'10%'}}>TAKE A PRODUCT TOUR <span style={{fontSize:'24px'}}>&#8594;</span></TryItButton></Link>
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: left;
`

const Subtitle = styled.h5`
font-size: 18px;
color: #1437BF;
letter-spacing: 0px;
margin-bottom: 12px;
text-align: left;
`

const FeaturesGrid = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: left;
`
const TryItButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: #071435;
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const GetStartedTitle = styled.h1`
  margin: 0 auto 32px;
  text-align: left;
`

const SectionSubTitle = styled.p`
color: ${props => props.theme.color.black.regular};
display: flex;
justify-content: left;
font-size:24px;
margin: 0 auto 60px;
line-height:30px;
text-align: left;
`