import React from "react"
import styled from "styled-components"
import Product1 from "../../images/product/commerce2x-seller-price.jpg"


import { Row, Col } from 'react-grid-system';

import { Section, Container } from "../global"

// const props = {
//   regular: () => ({
//     light: boolean('Light variant (light)', false),
//   }),
//   clickable: () => ({
//     href: text('Href for clickable UI (href)', 'javascript:void(0)'),
//     light: boolean('Light variant (light)', false),
//   }),

// };
// const clickableProps = props.clickable();
const AllFeatures = () => (
  <StyledSection>
  
    <StyledContainer>
    <GetStartedTitle>Enterprise Commerce Marketplace Platform</GetStartedTitle>
    <p style={{color:'#fff'}}>Transform your eCommerce store into a full-fledged marketplace. With Multi-Vendor Marketplace, encourage merchants from diverse categories to sell products from a single storefront, increase product offerings, provide better customer selection and enhance your sales.</p>
  
    <img src={Product1} style={{width:'85%'}}></img>

    
      
      
       
    </StyledContainer>
  </StyledSection>
)

export default AllFeatures

const StyledSection = styled(Section)`
  background-color: #0B1344;
  // clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const StyledContainer = styled(Container)`
  /* transform: skewY(5deg);
  border-radius: 4px;
  background-image: linear-gradient(to top, #fefefe 0%, #fbfbfb 100%); */
  background-color:#0B1344;;
`

const SectionTitle = styled.h1`
  color: ${props => props.theme.color.white.regular};
  display: flex;
  justify-content: center;
  margin: 0 auto 20px;
  text-align: left;
  font-weight:400;
`
const SectionSubTitle = styled.p`
  color: ${props => props.theme.color.white.regular};
  display: flex;
  justify-content: left;
  font-size:18px;
  margin: 0 auto 60px;
  line-height:30px;
  text-align: left;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.white.regular};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  // max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:30px;
  flex-direction: column;
`

const FeatureTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align:center;
  font-weight:500;
`

const FeatureText = styled.p`
  text-align: center;
  
`
const GetStartedTitle = styled.h1`
color:#fff;
  margin: 0 auto 32px;
  text-align: left;
`
